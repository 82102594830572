@font-face {
  font-family: Geist;
  src: url(Geist-Regular.otf);
}

@font-face {
  font-family: Geist;
  src: url(Geist-Bold.otf);
  font-weight: bold;
}

body {
  font-family: Geist;
  border: 1px solid transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 1000px;
  width: 100%;
  background-color: #f8fcfd;
}

.row {
  width: 100%;
}

.menu-bar {
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #e6e6e6;
}

.menu-left {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.menu-title {
  margin-right: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

.menu-right {
  display: flex;
  align-items: center;
  margin-right: 20px; 
}

.social-icon {
  margin-left: 20px;
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  display: inline-block;
}

.social-icon img {
  width: 20px;
  height: 20px;
}

.Body-Title {
  text-align: center;
  margin-top: 130px;
  font-weight: bold;
  font-size: 43px;
}

.Body-Txt {
  text-align: center;
  margin: 20px 0 -20px 0;
}

.title-row {
  display: block;
  height: 250px;
}

.ImageUploader-DropDown-Box {
  width: 60%;
  height: 450px;
  border: 1px solid #ededed;
  text-align: center;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;  
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.box-bg {
  width: 98%;
  position: absolute;
}

.button-row {
  height: 23px;
  margin: 210px auto 0 auto;
  display: block;
}

.ImageUploader-thumbnail {
  margin-right: 10px;
  height: 125px;
  width: 125px;
  border-radius: 10px;
  float: left;
  /* background-color: white;  */
}

.ImageUploader-loading-thumbnail {
  height: 125px;
  width: 125px;
  border-radius: 10px;
  margin: 0 10px 0 10px;
  /* background-color: white;  */
}

.progress-bar {
  margin: 10px auto 50px auto;
  height: 10px;
  width: 125px;
}

.ImageUploader-para {
  max-width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.ImageUploader-imgsrc {
  height: 150px;
  width: 150px;
  margin: 10px;
  border-radius: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*Dropzone Before*/
.ImageUploader-dropzone {
  height: 1000px;
  width: 100%;
  display: inline-block;
}

.ImageUploader-select-btn {
  padding: 12px 40px 12px 40px;
  font-weight:500;
  font-size: 20px;
  border-radius: 10px;
  background-color: #2e58ff;
  font-weight: bold;
}

.ImageUploader-drop-img {
  font-size: 15px;
  font-weight:400;
  margin: 10px 0 0 0;
  text-align: center;
  color: #5C5C5C;
}

/*Dropzone After*/
.ImageUploader-dropzoneAfter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImageUploader-add-btn {
  padding: 10px 35px 10px 35px;
  font-weight: bold;
  margin: 30px 0 30px 10%;
  float: right;
  border-color: #2e58ff;
  color: #2e58ff;
}

.ImageUploader-add-btn:hover {
  background: #2e58ff;
}

.ImageUploader-drop-para {
  font-size: 16px;
  margin: 30px 0 20px 0;
  padding-top: 10px;
  color: #5C5C5C;
  float: left;
}

.ImageUploader-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  float: left;
  padding-left: 20px;
}

/* Size Row */
.size-row {
  margin-top: 10px;
  width: 85%;
  float: left;
}

.thumbnail-size-img {
  height: 13px;
  float: left;
}

.ImageUploader-thumbnail-size {
  margin: -4px 0 0 -30px;
  float: left;
  font-size: 13px;
}

/* Timer Row  */
.timer-row {
  float: left;
}

.timer-img {
  height: 15px;
  margin-left: -28px;
}

.ImageUploader-thumbnail-timer {
  margin: 3px 0 30px -38px;
  float: left;
  font-size: 13px;
}

.delete-btn-container {
  margin-left: -40px;
}

.delete-btn {
  margin: 7px 0 0 80px;
  padding: 13px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  border-style: hidden;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.delete-btn-img {
  height: 15px;
  width: 15px;
  margin: -25px 10px 5px -7px;
}

/* Compress Image Button and Box*/
.compress-images-btn {
  margin: 40px 0 20px 0;
  padding: 15px 0 15px 0;
  width: 95%;
  background-color: #2e58ff;
  font-weight: bold;
}

.ImageUploader-DropDown-Box-After {
  width: 60%;
  min-height: 30%;
  border: 1px solid #ededed;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;  
  display: block;
  position: relative;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 50px;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* Loading Page */
progress {
  width: 100%;
  height: 20px;
}

progress::-webkit-progress-bar {
  background-color: #f1f1f1;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: #007bff;
  border-radius: 10px;
}

.ImageUploader-loading-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


/* Download Page CSS */
.ImageUploader-dl-Box-After {
  width: 60%;
  min-height: 30%;
  border: 1px solid #ededed;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;  
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 50px;
}

.ImageUploader-dl-para {
  font-size: 20px;
  display: flex;
  margin: 30px auto 10px auto;
}

.ImageUploader-dl-para-compressing {
  margin: 30px auto 10px auto;
  font-size: 20px;
}

.ImageUploader-dl-para-child {
  padding-left: 5%;
  padding-right: 5%;
  margin: 0 auto 0 auto;
}

.thumbnail-stats {
  position: relative;
  margin-top: -30px;
}

.thumbnail-stats span {
  border: 1px solid white;
  color: white;
  border-radius: 10px;
  font-size: 13px;
  padding: 3px;
  margin-left: 1px;
}

.ImageUploader-dl-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 20px;
}

.ImageUploader-dl-thumbnail {
  height: 125px;
  width: 125px;
  border-radius: 10px;
  margin-right: 10px;
  /* background-color: white;  */
}

.dl-btn {
  margin: 7px 0 0 80px;
  padding: 13px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  border-style: hidden;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.dl-btn-img {
  height: 13px;
  width: 13px;
  margin: -24px 10px 5px -6px;
}

/* DL Timer Row  */
.timer-compare-row {
  float: left;
  margin-top: 5px
}

.timer-compare-img {
  height: 12px;
  float: left;
  margin: 7px -40px 0 5px;
}

.ImageUploader-thumbnail-prev-timer {
  font-size: 12px;
  margin-left: -42px;
  display: inline-block;
  color: grey;
}

.ImageUploader-thumbnail-compare-timer {
  margin: -34px 0 0 50px;
  float: right;
  font-size: 12px;
  font-weight: bold;
}

.arrow-compare-timer {
  height: 9px;
  width: 9px;
  margin: -76px 0 0 25px;
}

/* DL Size Row */
.size-compare-row {
  float: left;
  margin-top: 25px;
  margin-left: -110px;
}

.thumbnail-compare-size-img {
  height: 12px;
  float: left;
  margin: 7px 5px 0 -2px;
}

.ImageUploader-thumbnail-prev-size {
  font-size: 12px;
  display: inline-block;
  color: grey;
  margin-left: -55px;
}

.ImageUploader-thumbnail-compare-size {
  margin: -34px 0 0 50px;
  float: right;
  font-size: 12px;
  font-weight: bold;
}

.arrow-compare-size {
  height: 9px;
  width: 9px;
  margin: -76px 0 0 15px;
}

.dl-images-btn {
  margin: 15px 0 0 0;
  padding: 15px 0 15px 0;
  width: 95%;
  background-color: #2e58ff;
  border-radius: 10px;
  font-weight: bold;
}

.resize-more-images-btn {
  margin: 10px 0 20px 0;
  padding: 15px 0 15px 0;
  width: 95%;
  border-radius: 10px;
  font-weight: bold;
}

.resize-more-images-btn:hover {
  background-color: #2e58ff;
}

.calc-total-row {
  display:flex;
  justify-content:space-between;
  padding: 0 2.5% 0 2.5%;
}

.calc-total {
  background-color: #edf9fd;
  text-align: center;
  font-size: 15px;
  width: 32.5%;
  height: 100%;
  border-radius: 10px;
  display: inline-block;
}

.num {
  font-weight: bold;
  font-size: 25px;
  padding-top: 10px;
  margin-bottom: -3px;
}

.footer {
  background-color: white;
  height: 40px;
  padding-top: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #f7f7f7;
}

.copyright {
  text-align: center;
  color: #808080;
}